/**
 * Centralised wrapper export for the app, which can be
 * shared with Storybook; global styles, providers, etc.
 */

import React from 'react';
import T from 'prop-types';

import { v4 as uuidv4 } from 'uuid';
import { ThemeProvider } from 'styled-components';
import { RestfulProvider } from 'restful-react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import {
	BREAKPOINTS,
	BrowserContainer,
	resolveBp,
} from '@deloitte-digital-au/dd-breakpoint-container';
import { Normalize } from 'styled-normalize';
import deepMerge from 'deepmerge';
import { isEqual, isEmpty, sortBy } from 'lodash';

import config from 'jss-boilerplate/temp/config';
import ExperienceEditorContext from 'core/sitecore/ExperienceEditorContext.tsx';
import {
	getAccessToken,
	setSelectedAccountNumber,
	getSelectedAccountNumber,
	getAccountSwitchSplash,
	setSessionTimeLeft,
	getSessionTimeLeft,
} from 'core/browserStorage';
import { AKAMAI_BOT_FLAG, X_CORRELATION_APP } from 'core/constants';
import { MemberContext, fallbackMember } from 'core/sitecore/member';
import { objSet } from 'core/utils/object-utils';
import { getSitecorePageState } from 'core/utils/sitecore-utils';
import { doRefreshToken } from 'core/utils/api-utils';
import GlobalStyles from 'core/styles/GlobalStyles';
import Fonts from 'core/styles/fonts';
import { localTheme, getThemeHelpers } from 'pods/theme';
import { isStorybook } from 'stories/utils/index.ts';

// Global utility for broadcasting to the UI what the user's
// current input type is (mouse/keyboard/touch) , and intent.
// Chiefly used for applying focus ring styles for keyboard users
// only (attached to CSS :focus states) and not :focus onClick
// Adds data attributes to <html/> tag:
// - 'data-whatinput'
// 		- for what the most recent interaction was triggered by (e.g. clicked mouse = 'mouse', pressed key = 'keyboard')
// - 'data-whatintent'
// 		- for what the 'next' interaction looks like it'll be (e.g. moving mouse = 'mouse')
import 'what-input';

// ------------------------
// App Context

export const AppContext = React.createContext({});

// ------------------------
// App Breakpoint
export const AppBreakpoint = React.createContext(false);
// ------------------------
// ? NOTE: Due to limitations of `bp()` mixin not being able to handle
// ? full bp queries, we need the complementary breakpoint to the `MOBILE_BP_QUERY` below.
// ! This could feasibly be removed if the `bp()` mixin is refactored to support queries
export const DESKTOP_BP = 'l';
// ------------------------
export const XS_BP_QUERY = '0,xs';
export const MOBILE_BP_QUERY = '0,s';
export const TABLET_BP_QUERY = 's,m';

export const isMobile = bp => resolveBp(MOBILE_BP_QUERY, bp);

// ------------------------
// Theme

const THEME_NAME = 'theme-light';

// ------------------------

export default class AppContainer extends React.Component {
	static propTypes = {
		children: T.node.isRequired,
		sitecoreContext: T.object.isRequired,
		routeFields: T.shape({
			hideBreadcrumbs: T.shape({
				value: T.bool.isRequired,
			}).isRequired,
		}).isRequired,
		dbIntersectionComponents: T.array.isRequired,
	};

	state = {
		notification: '',
		//If true, it will close the ER45 banner to Investment Breakdown component
		isInvestmentNotificationClose: false,
		accountSwitcherModalTag: '',
		isAccountSwitchTriggered: false,
		isAccountSwitched: false,
		isAccountSplashModalHidden:
			getAccountSwitchSplash() === 'hide' ? true : false,
		// This is just to listen to requests using useEffect; value doesn't matter as long as it changes
		backEndRequest: false,
		// Skeleton load states
		isSkeletonLoadDone: null,
		dbSkeletonLoaderDeps: {},
		dbComponents: [],
		isShowLivePersonModal: false,
		fyResponseData: [],
		talSsoUrl: '',
		isTalSsoUrlLoading: false,
		triggerTalSsoUrl: undefined,
		talSsoError: false,
		unreadMsgCount: null,
		productName: '',
		accountNumber: '',
		isWithdrawalForm: false,
		accountBalance: '',
		isSmsfWithdrawal: false,
		isSmsfTransfer: true,
		triggerOtp: false,
		withdrawalType: '',
		verificationToken: '',
		applicationNumber: '',
		identityVerificationSentDate: '',
		identityVerificationStatus: '',
		noiDataObject: {},
		noiEditDataObject: {},
		directDebitDataObject: {},
		pensionNextPaymentDate: {},
		bsbBankName: '',
		spouseContributionsDataObject: {},
		centrelinkObject: {},
		restrictionNotifObject: {},
	};

	handleSkeletonLoaderDeps = dbSkeletonLoaderDeps => {
		this.setState({ dbSkeletonLoaderDeps });
	};

	handleDbComponents = dbComponent => {
		this.setState(prevState => ({
			dbComponents: [...prevState.dbComponents, dbComponent],
		}));
	};

	handleSetNotification = notification => {
		this.setState({ notification });
	};

	handleSetInvestmentNotifClose = isInvestmentNotificationClose => {
		this.setState({ isInvestmentNotificationClose });
	};

	_getMemberObject = isSitecoreEE => {
		const { sitecoreContext } = this.props;

		// "Fallback member" only applies in Storybook and EE environment
		const appIMemberObject =
			((isStorybook() || isSitecoreEE) && fallbackMember) ||
			sitecoreContext?.memberContext?.memberData ||
			{};

		return appIMemberObject;
	};

	_getThemeProviderObject = () => {
		const appThemeObject = localTheme;
		const activeTheme =
			appThemeObject.themes.find(themeObj => themeObj.id === THEME_NAME) ?? {};

		activeTheme.global = deepMerge(
			appThemeObject.global,
			activeTheme.global || {},
		);
		activeTheme.variables = deepMerge(
			appThemeObject.variables,
			activeTheme.variables || {},
		);

		const themeHelpers = getThemeHelpers(activeTheme);

		const theme = {
			...activeTheme,
			...themeHelpers,

			// ------------------------

			// `styled-components-breakpoint` package settings
			breakpoints: BREAKPOINTS,
		};

		return theme;
	};

	handleAccountSwitcherModal = accountSwitcherModalTag => {
		this.setState({ accountSwitcherModalTag });
	};

	handleAccountSwitchClickEvent = isAccountSwitchTriggered => {
		this.setState({ isAccountSwitchTriggered });
	};

	handleAccountSplashModal = isAccountSplashModalHidden => {
		this.setState({ isAccountSplashModalHidden });
	};

	handleLivePersonModal = isShowLivePersonModal => {
		this.setState({ isShowLivePersonModal });
	};

	handleAccountSwitch = isAccountSwitched => {
		this.setState({ isAccountSwitched });
	};

	handleBackEndRequest = backEndRequest => {
		this.setState({ backEndRequest });
	};

	handleIsSkeletonLoadDone = isSkeletonLoadDone => {
		this.setState({ isSkeletonLoadDone });
	};

	handleFYRequest = fyResponseData => {
		this.setState({ fyResponseData });
	};

	handleTalSsoUrl = talSsoUrl => {
		this.setState({ talSsoUrl });
	};

	handleIsTalSsoUrlLoading = isTalSsoUrlLoading => {
		this.setState({ isTalSsoUrlLoading });
	};

	handleTalSsoError = talSsoError => {
		this.setState({ talSsoError });
	};

	handleTriggerTalSsoUrl = triggerTalSsoUrl => {
		this.setState({ triggerTalSsoUrl });
	};

	handleUnreadMsgCount = unreadMsgCount => {
		this.setState({ unreadMsgCount });
	};

	handleProductName = productName => {
		this.setState({ productName });
	};

	handleAccountNumber = accountNumber => {
		this.setState({ accountNumber });
	};

	handleIsWithdrawalForm = isWithdrawalForm => {
		this.setState({ isWithdrawalForm });
	};
	handleAccountBalance = accountBalance => {
		this.setState({ accountBalance });
	};
	handleIsSmsfWithdrawal = isSmsfWithdrawal => {
		this.setState({ isSmsfWithdrawal });
	};

	handleIsSmsfTransfer = isSmsfTransfer => {
		this.setState({ isSmsfTransfer });
	};
	handleTriggerOtp = triggerOtp => {
		this.setState({ triggerOtp });
	};

	handleNoiDataObject = noiDataObject => {
		this.setState({ noiDataObject });
	};

	handleNoiEditDataObject = noiEditDataObject => {
		this.setState({ noiEditDataObject });
	};

	handleDirectDebitDataObject = directDebitDataObject => {
		this.setState({ directDebitDataObject });
	};

	handlePensionNextPaymentDate = pensionNextPaymentDate => {
		this.setState({ pensionNextPaymentDate });
	};

	handleWithdrawalType = withdrawalType => {
		this.setState({ withdrawalType });
	};

	handleVerificationToken = verificationToken => {
		this.setState({ verificationToken });
	};

	handleApplicationNumber = applicationNumber => {
		this.setState({ applicationNumber });
	};
	handleIdentityVerificationSentDate = identityVerificationSentDate => {
		this.setState({ identityVerificationSentDate });
	};
	handleIdentityVerificationStatus = identityVerificationStatus => {
		this.setState({ identityVerificationStatus });
	};
	handleBsbBankName = bsbBankName => {
		this.setState({ bsbBankName });
	};
	handleSpouseContributionsDataObject = spouseContributionsDataObject => {
		this.setState({ spouseContributionsDataObject });
	};
	handleCentrelinkObject = centrelinkObject => {
		this.setState({ centrelinkObject });
	};
	handleRestrictionNotifObject = restrictionNotifObject => {
		this.setState({ restrictionNotifObject });
	};

	// Set Skeleton Loader delay here
	delay = 20;
	timer;

	componentDidUpdate(prevProps, prevState) {
		/** Start of Skeleton Loader in componentDidUpdate
		 * isSkeletonLoadDone can be set via 2 ways
		 * 1. Timer
		 * 2. All dashboard API's are done loading - Each DB component
		 */

		// 1. Timer
		if (prevState.dbComponents !== this.state.dbComponents) {
			if (this.props.dbIntersectionComponents !== undefined) {
				if (
					isEqual(
						sortBy(this.props.dbIntersectionComponents),
						sortBy(this.state.dbComponents),
					)
				) {
					this.timer = setTimeout(() => {
						this.handleIsSkeletonLoadDone(true);
					}, this.delay * 1000);
				}
			}
		}

		if (prevState.isSkeletonLoadDone !== this.state.isSkeletonLoadDone) {
			if (this.state.isSkeletonLoadDone) {
				clearTimeout(this.timer);
			}
		}

		// 2. Dashboard API's
		if (
			!isEqual(prevState.dbSkeletonLoaderDeps, this.state.dbSkeletonLoaderDeps)
		) {
			if (!isEmpty(this.state.dbSkeletonLoaderDeps)) {
				if (
					Object.values(this.state.dbSkeletonLoaderDeps).every(
						value => value === false,
					)
				) {
					this.handleIsSkeletonLoadDone(true);
				}
			}
		}
		/** End of Skeleton Loader in componentDidUpdate */
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	render() {
		const { children, sitecoreContext, routeFields } = this.props;
		const { baseURL, lpDivId } = sitecoreContext?.metadata ?? {};
		const { selectedAccountNumber } =
			sitecoreContext?.memberContext?.memberData ?? {};
		const { database, pageState } = sitecoreContext ?? {};
		// check if the current env is inside Sitecore Experience Editor + Page state
		const isSitecoreEE =
			isExperienceEditorActive() || getSitecorePageState(pageState, database);

		const appMember = this._getMemberObject(isSitecoreEE);
		const appTheme = this._getThemeProviderObject();
		const sessionTimeLeft =
			sitecoreContext?.sessionTimeLeft ?? getSessionTimeLeft();

		const selectedAccountNumberLocalStorage = getSelectedAccountNumber();

		// Inject the baseURL into the member object so it's available via the Context API
		objSet(appMember, ['access', 'baseURL'], baseURL);

		// Inject selected account number into local storage
		const whatAccountNumberToPass =
			selectedAccountNumberLocalStorage === null ||
			selectedAccountNumberLocalStorage === '' ||
			typeof selectedAccountNumberLocalStorage === 'undefined'
				? selectedAccountNumber ?? ''
				: selectedAccountNumberLocalStorage;

		setSelectedAccountNumber(whatAccountNumberToPass);
		setSessionTimeLeft(parseInt(sessionTimeLeft));

		return (
			<AppContext.Provider
				value={{
					sitecoreContext,
					routeFields,
					notification: this.state.notification,
					handleSetNotification: this.handleSetNotification,
					isInvestmentNotificationClose: this.state
						.isInvestmentNotificationClose,
					handleSetInvestmentNotifClose: this.handleSetInvestmentNotifClose,
					accountSwitcherModalTag: this.state.accountSwitcherModalTag,
					handleAccountSwitcherModal: this.handleAccountSwitcherModal,
					isAccountSwitchTriggered: this.state.isAccountSwitchTriggered,
					handleAccountSwitchClickEvent: this.handleAccountSwitchClickEvent,
					isAccountSplashModalHidden: this.state.isAccountSplashModalHidden,
					handleAccountSplashModal: this.handleAccountSplashModal,
					isAccountSwitched: this.state.isAccountSwitched,
					handleAccountSwitch: this.handleAccountSwitch,
					backEndRequest: this.state.backEndRequest,
					handleBackEndRequest: this.handleBackEndRequest,
					isSkeletonLoadDone: this.state.isSkeletonLoadDone,
					dbSkeletonLoaderDeps: this.state.dbSkeletonLoaderDeps,
					handleSkeletonLoaderDeps: this.handleSkeletonLoaderDeps,
					dbComponents: this.state.dbComponents,
					handleDbComponents: this.handleDbComponents,
					handleLivePersonModal: this.handleLivePersonModal,
					isShowLivePersonModal: this.state.isShowLivePersonModal,
					fyResponseData: this.state.fyResponseData,
					handleFYRequest: this.handleFYRequest,
					talSsoUrl: this.state.talSsoUrl,
					isTalSsoUrlLoading: this.state.isTalSsoUrlLoading,
					handleIsTalSsoUrlLoading: this.handleIsTalSsoUrlLoading,
					talSsoError: this.state.talSsoError,
					handleTalSsoError: this.handleTalSsoError,
					triggerTalSsoUrl: this.state.triggerTalSsoUrl,
					handleTriggerTalSsoUrl: this.handleTriggerTalSsoUrl,
					handleTalSsoUrl: this.handleTalSsoUrl,
					unreadMsgCount: this.state.unreadMsgCount,
					handleUnreadMsgCount: this.handleUnreadMsgCount,
					productName: this.state.productName,
					handleProductName: this.handleProductName,
					accountNumber: this.state.accountNumber,
					handleAccountNumber: this.handleAccountNumber,
					isWithdrawalForm: this.state.isWithdrawalForm,
					handleIsWithdrawalForm: this.handleIsWithdrawalForm,
					accountBalance: this.state.accountBalance,
					handleAccountBalance: this.handleAccountBalance,
					isSmsfWithdrawal: this.state.isSmsfWithdrawal,
					handleIsSmsfWithdrawal: this.handleIsSmsfWithdrawal,
					isSmsfTransfer: this.state.isSmsfTransfer,
					noiDataObject: this.state.noiDataObject,
					handleNoiDataObject: this.handleNoiDataObject,
					noiEditDataObject: this.state.noiEditDataObject,
					directDebitDataObject: this.state.directDebitDataObject,
					handleDirectDebitDataObject: this.handleDirectDebitDataObject,
					pensionNextPaymentDate: this.state.pensionNextPaymentDate,
					handlePensionNextPaymentDate: this.handlePensionNextPaymentDate,
					triggerOtp: this.state.triggerOtp,
					handleTriggerOtp: this.handleTriggerOtp,
					handleNoiEditDataObject: this.handleNoiEditDataObject,
					handleIsSmsfTransfer: this.handleIsSmsfTransfer,
					withdrawalType: this.state.withdrawalType,
					handleWithdrawalType: this.handleWithdrawalType,

					verificationToken: this.state.verificationToken,
					handleVerificationToken: this.handleVerificationToken,

					applicationNumber: this.state.applicationNumber,
					handleApplicationNumber: this.handleApplicationNumber,

					identityVerificationSentDate: this.state.identityVerificationSentDate,
					handleIdentityVerificationSentDate: this
						.handleIdentityVerificationSentDate,

					identityVerificationStatus: this.state.identityVerificationStatus,
					handleIdentityVerificationStatus: this
						.handleIdentityVerificationStatus,
					bsbBankName: this.state.bsbBankName,
					handleBsbBankName: this.handleBsbBankName,
					spouseContributionsDataObject: this.state
						.spouseContributionsDataObject,
					handleSpouseContributionsDataObject: this
						.handleSpouseContributionsDataObject,
					centrelinkObject: this.state.centrelinkObject,
					handleCentrelinkObject: this.handleCentrelinkObject,
					restrictionNotifObject: this.state.restrictionNotifObject,
					handleRestrictionNotifObject: this.handleRestrictionNotifObject,
				}}
			>
				<MemberContext.Provider value={appMember}>
					<RestfulProvider
						base={config.australianSuperApi?.apiDomain}
						resolve={data => data}
						requestOptions={async () => {
							// use latest token from local storage
							let accessToken = getAccessToken();
							// Check if we need to do a refreshtoken for the case when the user has been
							// staying in the form for too long
							if (
								accessToken &&
								(!isStorybook() || !isExperienceEditorActive())
							)
								await doRefreshToken();

							accessToken = getAccessToken();

							return {
								headers: {
									'Authorization': accessToken ? `Bearer ${accessToken}` : '',
									'X-API-Key': config?.externalServices?.coarseGrainApiKey, // API Coarse grain
									'X-Correlation-Id': uuidv4(), // FED initiated correlationID to fulfill the
									// requirements on https://australiansuper.atlassian.net/browse/DRP-179 as part of Remediation initiatives
									'X-Correlation-App': X_CORRELATION_APP, // Link detection of request origin
								},
								credentials:
									AKAMAI_BOT_FLAG === true ? 'include' : 'same-origin', // akamai bot detection
							};
						}}
						onRequest={() => {
							this.handleBackEndRequest(!this.state.backEndRequest);
						}}
					>
						<ThemeProvider theme={appTheme}>
							<ExperienceEditorContext.Provider value={isSitecoreEE}>
								<>
									{!isSitecoreEE && (
										<>
											{/**
											 * Commenting out the GTM component here in order to avoid duplication
											 * from what was declared in the public/index.html
											 *
											 * TO DO: Find a way to inject GTM script directly on the head tag
											 * of the site using the existing GTM component,
											 * instead of building it inside the body.
											 * As per https://jira.australiansuper.net.au/browse/MPORTAL-6438 the analytics team
											 * wants the GTM script to be in the head tag instead of being
											 * compiled in the body section.
											 *
											 *
											 * Old GTM Declaration:
											 * <GoogleTagManager {...{ gtmId }} />
											 */}

											<div style={{ display: 'none' }}>
												<div id={lpDivId} />
											</div>
										</>
									)}
									<Normalize />
									<GlobalStyles />
									<Fonts />
									<BrowserContainer>
										{bp => (
											<AppBreakpoint.Provider value={bp}>
												<>
													{/* Note: This is wrapped in a fragment to be
													certain that the above Provider only has one
													child, which is a strict requirement that would
													otherwise depend on how children are passed */}
													{children}
												</>
											</AppBreakpoint.Provider>
										)}
									</BrowserContainer>
								</>
							</ExperienceEditorContext.Provider>
						</ThemeProvider>
					</RestfulProvider>
				</MemberContext.Provider>
			</AppContext.Provider>
		);
	}
}
